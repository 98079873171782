<template>
  <div>
    <ViewTemplateReportPreview :panel="panel" :templateList="templateList" :templateReport="templateReport" />
  </div>
</template>

<script>
import ViewTemplateReportPreview from "@nixweb/nixloc-ui/src/component/template/ViewTemplateReportPreview.vue";

export default {
  name: "ReportProductView",
  components: {
    ViewTemplateReportPreview,
  },
  data() {
    return {
      panel: {
        module: "Estoque",
        title: "Produto",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
      templateList: {
        urlGetApi: "/odata/ProductByCustomerOData",
        showChecks: false,
      },
      templateReport: {
        fields: [
          {
            field: "numberRent",
            title: "Nº Locacão",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            selected: true,
            requiredOData: false,
          },
          {
            field: "customerName",
            title: "Cliente",
            type: "text",
            selected: true,
            requiredOData: false,
            styleHeader: "width:200px;",
            styleBody: "max-width: 200px; white-space: nowrap;",
          },
          {
            field: "typeName",
            title: "Tipo",
            type: "text",
            selected: false,
            requiredOData: true,
          },
          {
            field: "productName",
            title: "Produto",
            type: "text",
            orderBy: "asc",
            selected: true,
            requiredOData: false,
          },
          {
            field: "categoryProductName",
            title: "Categoria",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            selected: false,
            requiredOData: false,
          },
          {
            field: "manufacturerName",
            title: "Fabricante",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            selected: true,
            requiredOData: false,
          },
          {
            field: "barCode",
            title: "Codigo Grupo",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            selected: false,
            requiredOData: false,
          },
          {
            field: "unitOfMeasurementName",
            title: "Unidade Medida",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            selected: false,
            requiredOData: false,
          },
          {
            field: "purchaseDate",
            title: "Data Compra",
            type: "date",
            requiredOData: false,
            selected: false,
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "totalStock",
            title: "Estoque",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            selected: false,
            requiredOData: false,
          },
          {
            field: "totalReserved",
            title: "Reservado",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            selected: true,
            requiredOData: false,
          },
          {
            field: "totalRented",
            title: "Locado",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            selected: true,
            requiredOData: false,
          },
          {
            field: "totalReturned",
            title: "Retornado",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            selected: true,
            requiredOData: false,
          },
          {
            field: "statusName",
            title: "Status",
            type: "text",
            classCssTitle: "text-center",
            fieldComparison: "statusName",
            requiredOData: true,
            selected: true,
            classCssBody: "text-center",
          },
          {
            field: "replacementValue",
            title: "Reposição",
            type: "currency",
            classCssTitle: "text-center",
            requiredOData: false,
            selected: false,
            classCssBody: "text-center",
          },
          {
            field: "totalReplacementValue",
            title: "T.Reposição",
            type: "currency",
            classCssTitle: "text-center",
            requiredOData: false,
            selected: false,
            classCssBody: "text-center",
          },
        ],
        rules: [
          {
            name: "numberRent",
            title: "Nº Locação",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "customerId",
            title: "Cliente",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/crm/customer/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "type",
            title: "Tipo",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Equipamento", value: 1 },
              { title: "Acessório", value: 2 },
              { title: "Serviço", value: 3 },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "typeProductId",
            title: "Tipo de Produto",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/stock/type-product/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "productName",
            title: "Produto",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "categoryProductId",
            title: "Categoria",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/stock/category-product/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "manufacturerId",
            title: "Fabricante",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/stock/manufacturer/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "barCode",
            title: "Codigo Grupo",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "unitOfMeasurementId",
            title: "Unidade Medida",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/stock/unit-of-measurement/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "purchaseDate",
            title: "Data Compra",
            icon: "fas fa-database",
            typeField: "dateTime",
            type: "field",
            props: {
              format: "DD/MM/YYYY",
              type: "date",
              range: true,
            },
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: "totalStock",
            title: "Estoque",
            icon: "fas fa-database",
            typeField: "numeric",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "totalReserved",
            title: "Reservado",
            icon: "fas fa-database",
            typeField: "numeric",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "totalRented",
            title: "Locado",
            icon: "fas fa-database",
            typeField: "numeric",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },

          {
            name: "totalAvailable",
            title: "Disponível",
            icon: "fas fa-database",
            typeField: "numeric",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "status",
            title: "Status",
            icon: "fas fa-database",
            typeField: "checkbox",
            type: "field",
            options: [
              { title: "Ativo", value: 1 },
              { title: "Inativo", value: 2 },
            ],
            notDuplicate: true,
            isRequired: false,
          },
          {
            name: ["totalStock", "totalReserved", "totalRented", "totalReplacementValue"],
            title: "Totalização",
            icon: "fas fa-sigma",
            typeField: "checkbox",
            classCss: "primary",
            type: "totalization",
            options: [
              {
                title: "Total Estoque",
                value: "sum_TotalStock",
                aggregate: {
                  title: "Total Estoque",
                  value: "sumTotalStock",
                  type: "numeric",
                },
              },
              {
                title: "Total Reservado",
                value: "sum_TotalReserved",
                aggregate: {
                  title: "Total Reservado",
                  value: "sumTotalReserved",
                  type: "numeric",
                },
              },
              {
                title: "Total Locado",
                value: "sum_TotalRented",
                aggregate: {
                  title: "Total Locado",
                  value: "sumTotalRented",
                  type: "numeric",
                },
              },
              {
                title: "Total Locado x Valor Reposição",
                value: "sum_TotalReplacementValue",
                aggregate: {
                  title: "Total Locado x Valor Reposição",
                  value: "sumTotalReplacementValue",
                  type: "currency",
                },
              },
            ],
            notDuplicate: true,
            isRequired: false,
          }
        ],
      },
    };
  },
};
</script>
